import React, { Suspense, useEffect, useRef, useState } from "react";
import { Color } from "three";
import { Canvas } from "@react-three/fiber";
import {
  Bounds,
  Environment,
  Grid,
  Loader,
  OrbitControls,
  Resize,
  useBounds,
} from "@react-three/drei";
import { useLoader } from "@react-three/fiber";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader";
import { Typography } from "antd";
const { Text } = Typography;

function GLBModel({ url, wireframe }: { url: string; wireframe: boolean }) {
  const gltf = useLoader(GLTFLoader, url, (loader) => {
    const dracoLoader = new DRACOLoader();
    dracoLoader.setDecoderPath("/draco-gltf/");
    loader.setDRACOLoader(dracoLoader);
  });
  const bounds = useBounds();
  useEffect(() => {
    if (bounds && gltf.scene) {
      bounds.refresh(gltf.scene).clip().fit();
    }
  }, [bounds, gltf, url]);
  gltf.scene.traverse((child) => {
    if (child.type === "Mesh") {
      const mesh = child as THREE.Mesh;
      const mat = mesh.material as THREE.MeshStandardMaterial;
      if (mat.type === "MeshStandardMaterial") {
        const mat = mesh.material as THREE.MeshStandardMaterial;
        mat.wireframe = wireframe;
      }
    }
  });
  return (
    <Resize>
      <primitive object={gltf.scene} />
    </Resize>
  );
}

export const ThreeDViewer = ({ url }: { url: string | null }) => {
  const parentRef = useRef<HTMLDivElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [ambientLightIntensity, setAmbientLightIntensity] = useState(0.0);
  const [environmentPreset, setEnvironmentPreset] = useState<
    "forest" | "sunset" | "sunrise" | "dawn" | "night"
  >("forest");
  const [showWireframe, setShowWireframe] = useState<boolean>(false);

  useEffect(() => {
    if (parentRef.current && canvasRef.current) {
      const parent = parentRef.current;
      const canvas = canvasRef.current;
      canvas.width = parent.clientWidth;
      canvas.height = canvas.width;
    }
  }, [parentRef, canvasRef]);
  /*
  const environmentPresets: MenuProps["items"] = [
    {
      key: "forest",
      label: "Forest",
    },
    {
      key: "sunset",
      label: "Sunset",
    },
    {
      key: "sunrise",
      label: "Sunrise",
    },
    {
      key: "dawn",
      label: "Dawn",
    },
    {
      key: "night",
      label: "Night",
    },
  ];
  const onEnvironmentMenuClick: MenuProps["onClick"] = ({ key }) => {
    setEnvironmentPreset(key as any);
  };
  */
  if (url === null) {
    return (
      <>
        <h1>X</h1>
      </>
    );
  } else {
    return (
      <div style={{ width: "100%" }} ref={parentRef}>
        {/*
        <Divider />
        <Space direction="vertical" size="middle" style={{ display: "flex" }}>
          <Card>
            <Flex gap="middle">
              <Text code>Ambient Light Intensity</Text>
              <Slider
                min={0.0}
                max={10.0}
                value={ambientLightIntensity}
                onChange={setAmbientLightIntensity}
                step={0.1}
                style={{ width: "250px" }}
              />
            </Flex>
            <Flex gap="middle">
              <Text code>Environment</Text>
              <Dropdown
                menu={{
                  items: environmentPresets,
                  onClick: onEnvironmentMenuClick,
                }}
              >
                <Space>
                  <a onClick={(e) => e.preventDefault()}>
                    {environmentPreset.toUpperCase()}
                  </a>
                  <DownOutlined />
                </Space>
              </Dropdown>
            </Flex>
            <Flex gap="middle">
              <Text code>Show Wireframe</Text>
              <Checkbox
                checked={showWireframe}
                onChange={(e) => setShowWireframe(e.target.checked)}
              />
            </Flex>
          </Card>
        </Space>
              */}
        <Canvas style={{ width: "100%" }} ref={canvasRef}>
          <Suspense>
            <ambientLight intensity={ambientLightIntensity} />
            <OrbitControls makeDefault />
            <Grid
              cellColor={new Color(0.8, 0.8, 0.8)}
              sectionColor={new Color(0.8, 0.8, 0.8)}
              infiniteGrid
              fadeDistance={25}
            />
            <Bounds maxDuration={0.25}>
              <GLBModel url={url} wireframe={showWireframe} />
            </Bounds>
            {/* @ts-ignore */}
            <Environment background preset={environmentPreset} blur={0.1} />
          </Suspense>
        </Canvas>
        <Loader />
      </div>
    );
  }
};
