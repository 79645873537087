import React from "react";
import { Card, Image, Space, Tooltip, Typography } from "antd";
import { AssetDeleteButton } from "./AssetDeleteButton";
import { AssetArchiveButton } from "./AssetArchiveButton";
import { AssetAddToDatasetButton } from "./AssetAddToDatasetButton";
const { Paragraph, Text } = Typography;

interface AssetCardProps {
  //TODO(art): add asset type
  asset: any;
  onClick?: (assetId: string) => void;
  onDelete?: (assetId: string) => void;
  onArchive?: (assetId: string) => void;
}

export const AssetCard: React.FC<AssetCardProps> = ({
  asset,
  onArchive,
  onClick,
  onDelete,
}) => {
  const handleOnClick = () => {
    if (onClick) {
      onClick(asset.id);
    }
  };

  const description =
    asset.description ||
    asset.meta.annotations?.synthetic?.blip ||
    "No Description";

  return (
    <Card
      hoverable
      cover={
        <Image
          src={asset.thumbnail_url}
          preview={false}
          fallback="./thumbnail_404.png"
          onClick={handleOnClick}
        />
      }
      actions={[
        <AssetArchiveButton
          onArchive={() => {
            if (onArchive) {
              onArchive(asset.id);
            }
          }}
        />,
        <AssetDeleteButton
          onDelete={() => {
            if (onDelete) {
              onDelete(asset.id);
            }
          }}
        />,
        <AssetAddToDatasetButton assetId={asset.id} />,
      ]}
    >
      <Space direction="vertical">
        <Paragraph
          italic
          ellipsis={{
            rows: 3,
            expandable: false,
            tooltip: (
              <Tooltip placement="bottom" title={description}>
                {description}
              </Tooltip>
            ),
          }}
        >
          {description}
        </Paragraph>
        <Text code>{asset.source_dataset_name}</Text>
      </Space>
    </Card>
  );
};
